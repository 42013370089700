export const prizeData = [
    {
      id: "1",
      warriors: "4096",
      winners: "2048",
      winner_payout: "10,172.53",
      losers: "2048",
      loser_payout: "-",
      total_payouts: "20,833,333.33"
    },
    {
      id: "2",
      warriors: "2048",
      winners: "1024",
      winner_payout: "16,276.04",
      losers: "1024",
      loser_payout: "4,069.01",
      total_payouts: "20,833,333.33"
    },
    {
      id: "3",
      warriors: "1024",
      winners: "512",
      winner_payout: "32,552.08",
      losers: "512",
      loser_payout: "8,138.02",
      total_payouts: "20,833,333.33"
    },
    {
      id: "4",
      warriors: "512",
      winners: "256",
      winner_payout: "65,104.17",
      losers: "256",
      loser_payout: "16,276.04",
      total_payouts: "20,833,333.33"
    },
    {
      id: "5",
      warriors: "256",
      winners: "128",
      winner_payout: "130,208.33",
      losers: "128",
      loser_payout: "32,552.08",
      total_payouts: "20,833,333.33"
    },
    {
      id: "6",
      warriors: "128",
      winners: "64",
      winner_payout: "260,416.67",
      losers: "64",
      loser_payout: "65,104.17",
      total_payouts: "20,833,333.33"
    },
    {
      id: "7",
      warriors: "64",
      winners: "32",
      winner_payout: "520,8333.33",
      losers: "32",
      loser_payout: "130,208.33",
      total_payouts: "20,833,333.33"
    },
    {
      id: "8",
      warriors: "32",
      winners: "16",
      winner_payout: "1,041,666.67",
      losers: "16",
      loser_payout: "260,416.67",
      total_payouts: "20,833,333.33"
    },
    {
      id: "9",
      warriors: "16",
      winners: "8",
      winner_payout: "2,083,333.33",
      losers: "8",
      loser_payout: "520,833.33",
      total_payouts: "20,833,333.33"
    },
    {
      id: "10",
      warriors: "8",
      winners: "4",
      winner_payout: "4,166,666.67",
      losers: "4",
      loser_payout: "1,041,666.67",
      total_payouts: "20,833,333.33"
    },
    {
      id: "11",
      warriors: "4",
      winners: "2",
      winner_payout: "8,333,333.33",
      losers: "2",
      loser_payout: "2,083,333.33",
      total_payouts: "20,833,333.33"
    },
    {
      id: "12",
      warriors: "2",
      winners: "1",
      winner_payout: "16,666,666.67",
      losers: "1",
      loser_payout: "4,166,666.67",
      total_payouts: "20,833,333.33"
    },
  ];
