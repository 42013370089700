import React from "react";
import { prizeData } from "./data";
import "./prizespage.scss"

const PrizesPage = () => {
  return (
    <div className="prizes-container">
      <div className="top-header h-screen">
        <div className="h-screen relative container mx-auto">
          <img src="/prizes_page/icon_lt.png" alt="prok icon" className="absolute top-32 md:top-32 lg:top-1/4 2xl:top-48 left-4 md:left-12 transform -translate-y-1/3 w-16 md:w-20 lg:w-29" />
          <img src="/prizes_page/icon_lb.png" alt="prok icon" className="absolute bottom-6 md:bottom-6 lg:bottom-12 left-4 md:left-12 w-16 md:w-24 lg:w-32" />
          <img src="/prizes_page/icon_rt.png" alt="prok icon" className="absolute top-29 md:top-29 lg:top-1/4 2xl:top-44 right-4 md:right-12 transform -translate-y-1/2 w-12 md:w-16 lg:w-29" />
          <img src="/prizes_page/icon_rb.png" alt="prok icon" className="absolute bottom-4 md:bottom-4 lg:bottom-10 right-4 md:right-12 w-24 md:w-28 lg:w-40" />
          <img src="/prizes_page/text_prok.png" alt="text icon" className="absolute top-1/3 md:top-1/4 left-1/2 transform -translate-x-1/2 w-full sm:w-2/3 container" />
        </div>
      </div>
      <div className="bg-blue font-staatliches pb-14">
        <div className="container w-full mx-auto px-4 md:px-10">
          <div className="text-center text-white pt-12 pb-6">
            <h2 className="text-2xl lg:text-3xl xl:text-4xl">
              Season One Prize Structure
            </h2>
            <p className="sm:text-xl xl:text-2xl">
              Guaranteed $200,000 USD Liquidity (provided by team)
            </p>
          </div>
          <div className="rounded-md md:rounded-xl overflow-x-auto hidden md:block">
            <table className="rounded-md md:rounded-xl w-full tracking-wider overflow-hidden shadow-lg ">
              <thead className="bg-app-blue-dark text-white p-8 ">
                <tr className="tracking-wider md:text-xl font-thin">
                  <th className="py-2">
                    Round
                  </th>
                  <th className="py-2">
                    Warriors
                  </th>
                  <th className="py-2">
                    Winners
                  </th>
                  <th className="py-2">
                    Payout (END)
                  </th>
                  <th className="py-2">
                    Losers
                  </th>
                  <th className="py-2">
                    Payout (END)
                  </th>
                  <th className="py-2">
                    Total (END)
                  </th>
                </tr>
              </thead>
              <tbody className="text-app-blue-dark md:text-lg">
                {prizeData.map((item, idx) => {
                  return (
                    <tr className={`bg-app-blue-${idx % 2 === 0 ? "100" : "light"} text-center`} key={idx}>
                      <td className="py-2">
                        {item.id}
                      </td>
                      <td className="py-2">
                        {item.warriors}
                      </td>
                      <td className="py-2">
                        {item.winners}
                      </td>
                      <td className="py-2">
                        {item.winner_payout}
                      </td>
                      <td className="py-2">
                        {item.losers}
                      </td>
                      <td className="py-2">
                        {item.loser_payout}
                      </td>
                      <td className="py-2">
                        {item.total_payouts}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className="sm:w-3/4 mx-auto md:hidden flex flex-col px-0 tiny:px-8 gap-6">
          {prizeData.map((item, idx) => (
            <div className="flex flex-col rounded-lg text-left" key={idx}>
              <span className="rounded-t-lg border border-white bg-app-blue-dark text-white text-bold py-1 text-center">
                Round {item.id}
              </span>
              <div className="bg-white text-app-blue-dark px-2 tiny:px-4">
                <div className="flex">
                  <div className="w-1/2 tiny:w-3/5 py-1 text-sm">Warriors :</div>{ item.warriors }
                </div>
                <div className="flex">
                  <div className="w-1/2 tiny:w-3/5 py-1 text-sm">Winners :</div>{ item.winners }
                </div>
                <div className="flex">
                  <div className="w-1/2 tiny:w-3/5 py-1 text-sm">Payout (END) :</div>{ item.winner_payout }
                </div>
                <div className="flex">
                  <div className="w-1/2 tiny:w-3/5 py-1 text-sm">Losers :</div>{ item.losers }
                </div>
                <div className="flex">
                  <div className="w-1/2 tiny:w-3/5 py-1 text-sm">Payout (END) :</div>{ item.loser_payout }
                </div>
                <div className="flex">
                  <div className="w-1/2 tiny:w-3/5 py-1 text-sm">Total (END) :</div>{ item.total_payouts }
                </div>
              </div>
            </div>
          ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrizesPage
