import React from 'react'
import Layout from '../components/layout/layout'
import PrizesPage from '../components/partials/PrizesPage'


const Prizes = () =>{
  return(
    <Layout page="prizes">
      <PrizesPage />
    </Layout>
  )
}

export default Prizes